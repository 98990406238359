import React, {Suspense, lazy} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../containers/Layout';
import Home from '../pages/Home';
import Search from '../pages/Search';
import Login from '../pages/Login';
// import Register from '../pages/Register';
import TyC from '../pages/TyC';
import PdP from '../pages/PdP';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import '../styles/global.css';
import{ UserProvider } from '../context/useLoginContext'
import { QueryClientProvider, QueryClient } from 'react-query';
import Loading from '../components/Loading';

// const Home = lazy(() => import('../pages/Home'));
// const Login = lazy(() => import('../pages/Login'));
// const Search = lazy(() => import('../pages/Search'));
// const TyC = lazy(() => import('../pages/TyC'));
// const PdP = lazy(() => import('../pages/PdP'));
// const Dashboard = lazy(() => import('../pages/Dashboard'));
// const NotFound = lazy(() => import('../pages/NotFound'));


const MemoizedLayout = React.memo(Layout);

const queryClient = new QueryClient()

const App = () => {
	return (
		<UserProvider>
			<QueryClientProvider client={queryClient} >
				<Router>
					<MemoizedLayout>
						<Suspense fallback={<Loading/>}>
							<Routes>
								<Route exact path="/" element={<Home/>} />
								<Route exact path="/login" element={<Login/>} />
								{/* <Route exact path="/register" element={<Register/>} /> */}
								<Route exact path="/search" element={<Search/>} />
								<Route exact path="/tyc" element={<TyC/>} />
								<Route exact path="/pdp" element={<PdP/>} />
								<Route exact path="/dashboard/:page" element={<Dashboard/>} />
								<Route path="*" element={<NotFound/>} />
							</Routes>
						</Suspense>
					</MemoizedLayout>
				</Router>
			</QueryClientProvider>
		</UserProvider>
	);
}

export default App;